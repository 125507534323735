import { lazy, Suspense } from "react";
import { Link } from "gatsby";

import { Layout, Meta, Section } from "~/ui";
import { StreamVideoFallback } from "~/components";
import { Routes } from "~/models";

import volunteerWeeding from "~/assets/images/volunteer-weeding.png";

const StreamVideo = lazy(() => import("../components/StreamVideo"));

export default function LandingPage(): JSX.Element {
  return (
    <Layout>
      <Section
        bgColor="creamGradient"
        baseBgColorTop="primary"
        roundedCorners={{ "top-left": true }}
      >
        <div className="row">
          <div className="col-12 col-lg-8 mb-4 mb-lg-0">
            <h1 className="d-flex flex-column text-primary overflow-hidden mb-5">
              <span>Make</span>
              <span className="display-1">Volunteering</span>
              <span className="position-relative align-self-end text-success">
                <span className="position-relative z-index-1 pe-md-4">
                  part of your future.
                </span>
                <span
                  className="position-absolute strokes stroke-1"
                  style={{
                    top: "-5%",
                    bottom: "-5%",
                    left: "-10%",
                    right: "0",
                  }}
                />
              </span>
            </h1>
            <div className="mb-4">
              <p className="lead">
                Volunteering is a great way to take action on a cause you care
                about while building skills and experiences that will help you
                on your pathway to finding future employment.
              </p>
            </div>
          </div>
          <div className="col-12 col-lg-4 text-center">
            <img
              src={volunteerWeeding}
              alt="Volunteer pulling out weeds"
              className="img-fluid"
              width={471}
              height={557}
            />
          </div>
        </div>
      </Section>
      <Section
        bgColor="offWhite"
        baseBgColorTop="creamGradientEnd"
        roundedCorners={{ "top-right": true }}
      >
        <div className="row mb-5">
          <div className="col-12 col-lg-6 text-center mb-4 mb-lg-0">
            {typeof window !== "undefined" && (
              <div className="my-5">
                <Suspense fallback={<StreamVideoFallback />}>
                  <StreamVideo
                    src="8ebc931efcdab39e795958bc545260a3"
                    title="Why volunteer video"
                    poster="https://videodelivery.net/8ebc931efcdab39e795958bc545260a3/thumbnails/thumbnail.gif?time=7s"
                  />
                </Suspense>
              </div>
            )}
            <blockquote>
              "Volunteering helps me put things in perspective, it keeps me in
              touch with my community and what other people's lives are like"
            </blockquote>
          </div>
          <div className="col-12 col-lg-6">
            <div>
              <h2 className="d-flex flex-column h1 text-primary mb-4">
                <span>Why</span>
                <span className="display-1">Volunteer?</span>
              </h2>
              <ul className="list-custom list-custom--checkmark ps-0 ps-md-2">
                <li>
                  <span>
                    Meet new friends and be a <mark>part of a community</mark>
                  </span>
                </li>
                <li>
                  <span>
                    Learn new experiences and <mark>gain new skills</mark>
                  </span>
                </li>
                <li>
                  <span>
                    <mark>Help an organisation or cause</mark> you're passionate
                    about
                  </span>
                </li>
                <li>
                  <span>
                    Add experience to <mark>your resume</mark>
                  </span>
                </li>
                <li>
                  <span>
                    Build <mark>confidence and communication skills</mark>
                  </span>
                </li>
                <li>
                  <span>
                    Volunteer with <mark>friends</mark>
                  </span>
                </li>
                <li>
                  <span>
                    <mark>Keep mentally and physically healthy</mark>
                  </span>
                </li>
                <li>
                  <span>
                    Give you a <mark>purpose</mark>
                  </span>
                </li>
                <li className="position-relative">
                  <span
                    className="position-absolute strokes stroke-2"
                    style={{
                      left: "-10%",
                      top: "-5%",
                      bottom: "-5%",
                      width: "300px",
                    }}
                  ></span>
                  <span style={{ zIndex: 1 }}>
                    For a <mark>challenge</mark>
                  </span>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div className="text-center mb-n5">
          <Link
            to={Routes.GettingReady}
            className="position-relative btn btn-lg btn-success z-index-1 mb-n4"
          >
            Get started
          </Link>
        </div>
      </Section>

      <Section bgColor="creamGradient" className="pt-6 pt-lg-7 pb-5">
        <div className="row">
          <div className="col-12 col-lg-6 text-center mb-4 mb-lg-0">
            {typeof window !== "undefined" && (
              <Suspense fallback={<StreamVideoFallback />}>
                <StreamVideo
                  src="62c789cdafff5c3c8be506f0e16234fd"
                  title="What is volunteering video"
                  poster="https://videodelivery.net/62c789cdafff5c3c8be506f0e16234fd/thumbnails/thumbnail.gif?time=4s"
                />
              </Suspense>
            )}
          </div>
          <div className="col-12 col-lg-6 d-flex justify-content-center align-items-center px-lg-4">
            <blockquote>
              "Volunteering in a word? Stimulating. And challenging. And
              sometimes tiring. But always rewarding - it's incredibly
              rewarding"
            </blockquote>
          </div>
        </div>
      </Section>

      <Section bgColor="violet200" className="py-6">
        <div className="text-center">
          <Link to={Routes.GettingReady} className="btn btn-lg btn-primary">
            Start your journey now
          </Link>
        </div>
      </Section>
    </Layout>
  );
}

export const Head = () => {
  return <Meta title="Home" />;
};
